import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";

import {pageScripts, pageMetaTag} from "../../utils/updateScripts";

export const fetchPageInfo = (page) => {
    return createAsyncThunk(
        `${page.toLowerCase()}PageInfo/fetchPageInfo`,
        async () => {
            const {request} = useHttp();
            const dataLang = localStorage.getItem('dataLang');
            const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';
            const {json} = await request(`api/getPage/${window.env.PROJECT_ID}/${page}${shortNameParam}`, 'GET');
            pageMetaTag(json.data)
            pageScripts(json.data);
            return await new Promise((resolve) => resolve(json));
        }
    );
};


export const extraReducers = (page) => {
    return (builder) => {
        builder
            .addCase(fetchPageInfo(page).pending, state => {
             //   console.log(page)
                state.loadingStatus = 'loading'
            })

            .addCase(fetchPageInfo(page).fulfilled, (state, action) => {
             //   console.log(page)
                state.loadingStatus = 'loaded';
                state.items = action.payload.data;
            })

            .addCase(fetchPageInfo(page).rejected, state => {
                state.loadingStatus = 'error';
            })
            .addDefaultCase(() => {
            })
    }
}
