import {createSlice} from "@reduxjs/toolkit";
import {extraReducers} from "./action";
import {fetchPageInfo} from "./action";


const initialState = {
    items: [],
    loadingStatus: 'loaded',
    form: {}
}

export const pageInfo = fetchPageInfo('ContactUs');

const contactUsSlice = createSlice({
        name: 'contactUsPageInfo',
        initialState,
        reducers: {
            updateForm: (state, action) => {
                state.form = action.payload;
            },
        },
        extraReducers: extraReducers('ContactUs')
    }
);

const {actions, reducer} = contactUsSlice;

export default reducer;

export const {updateForm} = actions;
